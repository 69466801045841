//Diana Quotes
const DianaQuotes = {
  "Results":[
    "They remind me of the unpomunprpnoinceable",
    "Well next time you should slip it in",
    "You can clutch my radiator as long as you need to.",
    "It’s fun sometimes to get really messed up.",
    `(At bar primi) "That was the best thing of a little thing that I've ever eaten.`,
    `It's like an amazing moment in my mouth right now.`,
    `Dan got distracted by rob's sausage bread.`,
    `Why do we need a waitress to be nice to us?   We have each other we don't need her!`,
    `Anyway so I'm really into alien porn now.`,
    `I think I've overshared tonight.`,
    `It’s more about the swinging demon dick`
  ]
}
export default DianaQuotes;