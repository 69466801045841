//Sarah L Quotes
const SarahQuotes = {
  "Results":[
    "To Dan B: “Don’t talk about things you used to do it doesn’t make you cool.”",
    "So this is gonna sound weird but it seems like something you would pick up to play with.",
    "I used to study historical German Long Sword.",
    "Well, you guys won’t get me a bullhorn or a cattle prod so I’m reduced to this.",
    "See how I protect you from these people?",
    "Rob you look nice again today.  Are you cheating on us with another office?",
    "I stress dream about shopping at fictional grocery stores.",
    "Tad is a beautiful bonsai tree.",
    "I’m gonna play a game with the entire office that’s about your coffee cup and i wanna make sure you’re not gonna be mad about it.",
    "…but you know, not bad for Tool wine.",
    "It’s so cute that you think I’m drunk enough to answer that.",
    "Go harass someone so I can make fun of you",
    "Finish your call and then I will yell at you.",
    "The nice thing about burritos is that you can prop them up.",
    "Bob is fluid, like a cat.",
    "If you want to think I’m making fun of you I’m all for it.",
    "This is the english language.  The worse it looks the correcter it is.",
    "Physics is so mathy.",
    "Even your exwife does shots better than you.",
    "Of all the things I've said to you, THAT'S the first one you wrote down?!",
    `Sarah: "Are you going to happy hour?"

    me: ”yes”

    Sarah: “Okay then i’ll wait and make fun of you there.`,
    "If I wanted to improve people’s lives I would’ve become a prostitute.",
    "Well, to be clear the only reason i am that way is because of the brain tumor.",
    "No no"
  ]
}
export default SarahQuotes;