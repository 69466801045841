//Kate Quotes
const KateQuotes = {
  "Results":[
    "Dammit all my mushrooms are in Brooklyn what am I gonna do?",
    "<record scratch silence in a big crowd at Q’s dinner> “OH MY GOD SUCH GOOD MOUTHFEEL”",
    "To prune: How many pairs of flip flops do you have!?  Did you shoot up a nail parlor?",
    "This would be a great time for me to tell you my Hugh Jackman story.",
    "I have a personal question for you: do you want to share a beet salad?",
    "The elevator motor burned out and tripped the fire alarms. The alarms work. But the elevator doesn’t.",
    "Okay, so I’m getting the impression that this party is more of a formal affair which is very concerning as I’m really drunk.",
    "I’ll check the car for dead squirrels when I get home.",
    "Oh my god I was tripping my tits off.",
    "Well, SOMEONE is differently-abled because whatever is happening over there is fucking retarded.",
    "I’m vehemently, publicly, anti-drunk-driving. Which is why I confine it to the property. ",
    "All good. I’ll just pour myself another glass of wine and change my underwear.",
    "Gesturing to a bathtub in an abandoned apartment in bushwick: “I’d definitely murder someone in that.”",
    "My only rule about shelter island is no corpses in the pool. Uli can attest to this. ",
    "After another guy asks for her number at Q’s holiday party: “I usually clean up at these things but damn!”",
    "In Berlin on our way to the philharmonic: “walking with beers is so civilized.”",
    "In Berlin: OMG guys you have to try this mouthwash",
    "I wish my WuTang name was Deviled Eggs"
  ]
}
export default KateQuotes;







