//Adam Quotes
const AdamQuotes = {
  "Results":[
    "None of my things are wired together.",
    "Denise went away for four days and I built a guitar. ",
    "I think these algorithms might be generated in a vacuum. Maybe a battery powered phallus enhancing vacuum, but a vacuum nonetheless.",
    "I mean, the men's nylon stockings with double stitched penis sheath makes sense, I just worry about the quality of the construction at that price point.",
    "Apparently when people are naked they’re not that proper. ",
    "Aren't chihuahuas already satire?",
    "She has no time and I have no shame and that’s the difference.",
    "Granted, our mechanic also invested in bitcoin and has an AR-15 under his desk.",
    "I’ve been working on my yodeling since David was born ",
    "I wanna hear about 600 pound impacted people again tell her that dan.",
    "I would vote for you. If I lived in Bergen county. And was racist.",
    "What does skeletor look like in the locker room?  You can write that one down I'll wait.",
    "In my heart of hearts, I know that you're only a pocket nazi.",
    "Well my wife's menopausal so I wouldn't know anything about hormones.",
    "We're brain dating",
    `Who was it who said "don't let ivanka off the hook because she looks like she smells good" ?`,
    `Referring to a guitar: "It's old with new knobs, so it's like a 60 year old stripper"`,
    "Musically I haven't grown that much but my kids have.",
    "You can Carly Fiorina up a lot.",
    "Seton Hall is like a scared straight program for artists",
    "I feel like my life can be divided up into before/after the day I found out about camels and emus."
  ]
}
export default AdamQuotes;